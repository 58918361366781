import React, {useState} from 'react';
import {hasAValue} from "../../uitls/SharedFunctions";
import VehicleFeatureBox from "../Features/VehicleFeatureBox";
import ImInterestedForm from "../ImInterestedForm";
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import ScrollToTopComponent from "../ScrollToTopComponent";
import {Animated} from "react-animated-css";

const data = [
    {
        image: "/img/featured_image_1.png",
        title: "Info",
        content: ""
    },
    {
        image: "/img/featured_image_2.png",
        title: stringsTranslate(words.words.city),
        content: ""
    },
    {
        image: "/img/featured_image_5.png",
        title: stringsTranslate(words.words.link_to_the_car),
        content: ""
    },
    // {
    //     image: "/img/featured_image_3.png",
    //     title: "Safty",
    //     content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    // },
    {
        image: "/img/featured_image_4.png",
        title: "Technical info",
        content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    },
    //
    // {
    //     image: "/img/featured_image_6.png",
    //     title: "Documentation",
    //     content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
    // }
]

export default function FeatureVehicleSection({vehicleData}) {
    const [interested, setInterested] = useState(false)

    return (
        <>
            {vehicleData ? <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row">
                        <div className={"col-10"}>
                            <div className="row">
                                <div className={"col-12 col-md-6 col-lg-6 my-3 res-margin"}>
                                    <iframe className="license-qr" width={"100%"} src={vehicleData.youtubeLink} title="YouTube video player"
                                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                    <button className="btn btn-bordered w-100 mt-1" onClick={()=> setInterested(!interested)}>{stringsTranslate(words.words.im_interested)}</button>
                                </div>

                                {interested && <div className={"col-12 col-md-6 col-lg-6 my-3 res-margin"}>
                                    <ImInterestedForm vehicle={vehicleData.id} setInterested={(item)=> setInterested(item)}/>
                                </div>}

                                {/*Vehicle info */}
                                <VehicleFeatureBox image={data[0].image} title={data[0].title}
                                                   boxContent={<>{hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.license_plate)}:  <span className={"remarkable-text text-right"}>{vehicleData.license_plate}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.brand)}: <span className={"remarkable-text"}>{vehicleData.merk}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.model)}: <span className={"remarkable-text"}>{vehicleData.handelsbenaming}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.type)}: <span className={"remarkable-text"}>{vehicleData.inrichting}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.color)}: <span className={"remarkable-text"}>{vehicleData.eerste_kleur}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.seats)}: <span className={"remarkable-text"}>{vehicleData.aantal_zitplaatsen}</span></p>}</>}/>

                                {/*Technical info*/}
                                <VehicleFeatureBox image={data[3].image} title={data[3].title}
                                                   boxContent={<>
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.construction_year)}: <span className={"remarkable-text"}>{vehicleData.datum_eerste_toelating_dt.toString().substring(0,4)}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.engine_capacity)}: <span className={"remarkable-text"}>{vehicleData.cilinderinhoud}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}> {stringsTranslate(words.words.cilinders)}: <span className={"remarkable-text"}>{vehicleData.aantal_cilinders}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.mass)}: <span className={"remarkable-text"}>{vehicleData.massa_rijklaar}</span></p>}
                                                       {hasAValue(vehicleData) && <p className={"fw-7"}>{stringsTranslate(words.words.economy_rating)}: <span className={"remarkable-text"}>{vehicleData.zuinigheidsclassificatie}</span></p>}
                                                   </>}/>

                                {/*Contact */}
                                {/*<VehicleFeatureBox image={data[1].image} title={data[1].title}*/}
                                {/*                   boxContent={<>*/}
                                {/*                       {hasAValue(vehicleData) && <p className={"fw-7"}><span className={"remarkable-text"}>{vehicleData.city}</span></p>}*/}
                                {/*                       /!*{hasAValue(vehicleData) && <p className={"fw-7"}>E: <span className={"remarkable-text"}>{vehicleData.owner_email}</span></p>}*!/*/}
                                {/*                       /!*{hasAValue(vehicleData) && <p className={"fw-7"}>M: <span className={"remarkable-text"}>{vehicleData.tel_number} </span></p>}*!/*/}
                                {/*                   </>}/>*/}

                                {/*Link to the car */}
                                <div className="col-12 col-md-6 col-lg-6 my-3 res-margin crusor-pointer"
                                     onClick={() => window.open(vehicleData.url)}>
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                        {/* Featured Image */}
                                        <div className="featured-img mb-3">
                                            <img className="avatar-sm" src={data[2].image} alt=""/>
                                        </div>
                                        {/* Icon Text */}
                                        <div className="icon-text">
                                            <h3 className="mb-2">{data[2].title}</h3>
                                            {hasAValue(vehicleData) && <>
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Animated className="col-2 blog mt-3 pt-1" animationInDelay={250} animationIn="jackInTheBox" isVisible={true}>
                            <div id="scrollDown" title="Scroll To down" onClick={()=> window.scrollTo({
                                top: window.outerHeight,
                                behavior: 'smooth'
                            })}>
                                <i className="fas fa-arrow-down" />
                            </div>
                        </Animated>

                    </div>
                </div>
            </section> : <h1></h1>}
        </>
    );
}
