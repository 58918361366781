import React from 'react';

export default function VehicleFeatureBox({image, title, boxContent}) {


    return (
        <div className="col-12 col-md-6 col-lg-6 my-3 res-margin">
            {/* Image Box */}
            <div className="image-box text-center icon-1 p-5">
                {/* Featured Image */}
                <div className="featured-img mb-3">
                    <img className="avatar-sm" src={image} alt=""/>
                </div>
                {/* Icon Text */}
                <div className="icon-text text-center">
                    <h3 className="mb-2 ">{title}</h3>
                    {boxContent}
                </div>
            </div>
        </div>
    );

}


